@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .custom-gradient {
        @apply bg-primary-dark;
        background: linear-gradient(135deg, rgba(85,32,32,1) 0%, rgba(33,21,21,1) 35%);
    }

    .btn {
        @apply cursor-pointer disabled:opacity-50 hover:text-primary hover:underline transition-all;
    }

    .header-1 {
        @apply font-bold text-[32px] leading-[38px] sm:text-[46px] sm:leading-[60px] md:text-[60px] md:leading-[84px] xl:text-[88px] xl:leading-[106px];
    }

    .header-2 {
        @apply font-bold text-[22px] leading-[30px] sm:text-[32px] sm:leading-[36px] md:text-[42px] md:leading-[51px] xl:text-[64px] xl:leading-[77px];
    }

    .header-3 {
        @apply font-bold text-[16px] leading-[19px] sm:text-[24px] sm:leading-[28px] md:text-[32px] md:leading-[36px] xl:text-[48px] xl:leading-[57px]
    }

    .header-4 {
        @apply font-bold text-[25px] leading-[30px] sm:text-[32px] sm:leading-[36px] md:text-[42px] md:leading-[52px] xl:text-[64px] xl:leading-[77px];
    }

    .header-5 {
        @apply font-bold text-[18px] leading-[26px] xl:text-[24px] xl:leading-[29px];
    }

    .figure-caption-1 {
        @apply font-bold font-sans text-[14px] leading-[18px] lg:text-[24px] lg:leading-[28px];
    }

    .cite {
        @apply not-italic font-normal font-sans text-primary text-[11px] leading-[14px] lg:text-[14px] lg:leading-[18px];
    }

    .text-1 {
        @apply font-body font-normal text-[14px] leading-[17px] sm:text-[18px] sm:leading-[26px] md:text-[22px] md:leading-[32px] xl:text-[37px] xl:leading-[44px];
    }

    .text-2 {
        @apply font-body text-[12px] leading-[14px] sm:text-[14px] sm:leading-[20px] md:text-[18px] md:leading-[26px] xl:text-[21px] xl:leading-[25px];
    }

    .text-3 {
        @apply font-body font-normal text-[12px] leading-[16px] sm:text-[13px] sm:leading-[18px] md:text-[14px] md:leading-[22px] lg:text-[19px] lg:leading-[23px];
    }

    .text-4 {
        @apply text-[20px] leading-[28px] lg:text-[24px] lg:leading-[32px] xl:text-[32px] xl:leading-[40px];
    }

    .offset-background::after {
        content: '';
        @apply absolute bottom-0 left-0 right-0 h-[12vh] bg-primary-light sm:h-48 md:h-64;
    }

    .custom-text-field .MuiFormLabel-root.MuiInputLabel-root {
        @apply text-4 z-10;
    }

    .custom-text-field .MuiFormHelperText-root,
    .custom-text-field .MuiFormLabel-root.MuiInputLabel-root {
        @apply font-light font-sans;
    }

    .custom-text-field .MuiFormHelperText-root {
        @apply text-error m-0 !important;
    }

    .custom-text-field .MuiTypography-root {
        @apply text-secondary-dark text-3;
    }

    .custom-text-field .MuiFormLabel-root.MuiInputLabel-root,
    .custom-text-field .MuiFormLabel-filled,
    .custom-text-field .Mui-focused,
    .custom-text-field .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
    .custom-text-field .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate {
        @apply text-primary-dark;
    }

    .custom-text-field .Mui-error.MuiFormControlLabel-root .MuiTypography-root ,
    .custom-text-field .Mui-error.MuiInputBase-root.MuiInput-root,
    .custom-text-field .Mui-error.MuiFormLabel-root.MuiInputLabel-root,
    .custom-text-field .Mui-error.MuiFormLabel-filled {
        @apply text-error;
    }

    .custom-text-field label+.MuiInputBase-root.MuiInput-root {
        @apply xl:mt-24;
    }

    .custom-text-field .MuiInputBase-root.MuiInput-root {
        @apply text-4;
    }

    .custom-text-field .MuiInputBase-root.MuiInput-root::after,
    .custom-text-field .MuiInputBase-root.MuiInput-root::before {
        @apply border-secondary-light border-b;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .picture-swiper .swiper-wrapper {
        width: 100vw;
    }

    .picture-swiper .swiper-slide {
        @apply max-w-[450px] lg:max-w-none;
    }

    .testimonials-swiper {
        @apply lg:pt-40;
    }

    .testimonials-swiper .swiper-slide-visible {
        @apply text-primary-light lg:text-secondary-light lg:hover:text-primary-light;
    }

    .testimonials-swiper .swiper-slide-visible .swiper-slide-overflow {
        @apply sm:opacity-50 lg:opacity-100;
    }

    .testimonial-section {
        @apply relative;
    }

    .testimonial-section::after {
        content: '';
        @apply hidden lg:block w-full h-[120px] bg-primary-light absolute bottom-0 z-10;
    }

    .custom-pagination-btn {
        @apply text-primary-light absolute top-1/2 -translate-y-1/2 w-32 h-32 lg:w-40 lg:h-40 xl:h-52 xl:w-52 bg-primary rounded-full z-30 flex items-center justify-center disabled:opacity-50;
    }

    .section-padding-base {
        @apply py-40 sm:py-64 md:py-72 lg:py-80 xl:py-96 2xl:py-[140px];
    }

    .section-space-base {
        @apply mt-40 sm:mt-64 md:mt-80 lg:mt-96 xl:mt-[104px] 2xl:mt-[120px];
    }

    .header-space-base {
        @apply mb-24 sm:mb-32 md:mb-40 lg:mb-56 xl:mb-64 2xl:mb-72;
    }

    .header-space-md {
        @apply mb-12;
    }

    .header-space-sm {
        @apply mb-8;
    }
}

.grecaptcha-badge { visibility: hidden; }

html {
    @apply scrollbar-thin scrollbar-track-primary-dark scrollbar-thumb-error;
}

body {
    @apply w-full;
}
